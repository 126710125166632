// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
    @if $mq2==false {
        @media ($maxmin: $mq01) {
            @content;
        }
    }

    @else {
        @media (min-width: $mq01) and (max-width: $mq2) {
            @content;
        }
    }
}

// breakpoint variables
$xlg: 2560px;
$lg : 1170px;
$md : 1024px;
$sm : 640px;
$xs : 480px;

// responsive font size mixin
@mixin font-size-map($font-size-map) {

    @each $breakpoint,
    $font-size in $font-size-map {
        @if $breakpoint==null {
            font-size: $font-size;
        }

        @else {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
            }
        }
    }
}

// font sizes

$html-font-size: (null: 16px,
    $md: 15px,
    $sm: 12px,
    $xs: 10px);

html {
    font-family: "Roboto", sans-serif;
    @include font-size-map($html-font-size);
}

// for mobile

@media screen and (max-width: 700px) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 12px,
            $xs: 10px);

    html {
        @include font-size-map($html-font-size);
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1370px) and (orientation: landscape) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 12px,
            $xs:10px);

    html {
        @include font-size-map($html-font-size);
    }
}

// iPad only

/* For portrait layouts only */
@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: portrait) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 12px,
            $xs: 10px);

    html {
        @include font-size-map($html-font-size);
    }
}

/* For landscape layouts only */
@media only screen and (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 12px,
            $xs: 10px);

    html {
        @include font-size-map($html-font-size);
    }
}

// iPad Pro

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 14px,
            $xs: 12px);

    html {
        @include font-size-map($html-font-size);
    }
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    $html-font-size: (null: 16px,
            $md: 15px,
            $sm: 14px,
            $xs: 12px);

    html {
        @include font-size-map($html-font-size);
    }
}