@import "../../../assets/scss/core/variables/colors.scss";

$primary-color-dark   : $light-green-800;
$primary-color-light  : $light-green-300;
$primary-color-lighter: $light-green-100;

$secondary-color-dark   : $grey-300;
$secondary-color-lighter: #eeeeee;

$white-color: $white;
$black-color: $black;

$box-shadow-color: #00000038;

$spinner-dark-color: #0000007a;

$greyColor: #7d7d7d;